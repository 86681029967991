import React, {useEffect} from 'react';
import axios from "axios";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import Linear from "../CircularLoader/CircularLoader";

let result = [];
let dataAnsQues = [];
let dataConQues = [];
let dashQuestion = 4;

export default function DashboardQuestions(props) {
    let answeredSurveyId = props.props.answeredSurveyId;
    let dashId = props.props.dashId;
    let reachId = props.props.reachId;
    const [linearLoader, setLinearLoader] = React.useState(true);
    const [limit, setLimit] = React.useState(1);

    useEffect( () => {
        if (limit === 1){
        axios.post(process.env.REACT_APP_API_URL + '/various/inquiries/get-dashboards-answered-questions', {answered_survey_id: parseInt(answeredSurveyId)})
            .then((res) => {
                dataAnsQues=(res.data.response);
            })
            .catch(() => {
              dataAnsQues=([]);
            })
            .finally(() => {
                setLinearLoader(false);
                setLimit(0);
            });
    }else{
            console.log("Limite "+limit);
    }
    }, [answeredSurveyId])


    const getConfiguredQuestions = () => {
        if (limit === 1){
        axios.post(process.env.REACT_APP_API_URL + '/various/inquiries/get-dashboards-configured-questions', {dashboard_id: parseInt(dashId), reach_id: parseInt(reachId)})
            .then((res) => {
                dataConQues=(res.data.response);
            })
            .catch(() => {
                dataConQues=([]);
            })
            .finally(() => {
                setLimit(0);
            });
    }else {
            console.log("Limite "+limit)
        }
    };


    const getFilters = () =>{
        result = dataAnsQues.filter(function (o1) {
            return dataConQues.some(function (o2) {
                return o1.questionId === o2.questionId; // return the ones with equal id
            });
        });
    }


    getConfiguredQuestions();
    getFilters();
    if (result.length > 0){
        dashQuestion = (1);
    }else {
        dashQuestion = (0);
    }
    if (dashQuestion === 1){
        return (
            <td>
                {linearLoader && <Linear/>}
                <div style={{textAlign: "center", alignContent: 'center', alignItems: 'center'}}>
                    <CheckIcon style={{textAlign: "center", alignContent: 'center', alignItems: 'center', color: "#00FF00"}} />
                </div>
            </td>
        );
    } else{
        return (
            <td>
                {linearLoader && <Linear/>}
                <div style={{textAlign: "center", alignContent: 'center', alignItems: 'center'}}>
                    <ClearIcon style={{textAlign: "center", alignContent: 'center', alignItems: 'center', color: "#FF0000"}} />
                </div>
            </td>
        );
    }
}
