import React, {useEffect} from 'react';
import axios from "axios";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import Linear from "../CircularLoader/CircularLoader";
let auditStatus = 4;

export default function AuditStatus(props) {
    let detailTaskId = props.props.detailTaskId;
    const [dataStaAud, setDataStaAud] = React.useState([]);
    const [linearLoader, setLinearLoader] = React.useState(true);
    const [limit, setLimit] = React.useState(1);


    useEffect( () => {
        if (limit === 1){
        axios.post(process.env.REACT_APP_API_URL + '/various/inquiries/get-dashboards-status-audit', {detail_task_id: parseInt(detailTaskId)})
            .then((res) => {
                setDataStaAud(res.data.response);
            })
            .catch(() => {
                setDataStaAud([]);
            })
            .finally(() => {
                setLinearLoader(false);
                setLimit(0);
            });
        }else {
            console.log("Limite "+limit)
        }
        }, [detailTaskId]);


        let audit = dataStaAud.filter(id => id.statusId === 2);
        let confirm = dataStaAud.filter(id => id.confirm === 1);
        if (audit.length > 0 && confirm.length > 0){
            auditStatus = (1);
        }else{
            auditStatus = (0);
        }

        if (auditStatus === 1){
            return(
                <td>
                    {linearLoader && <Linear/>}
                    <div style={{textAlign: "center", alignContent: 'center', alignItems: 'center'}}>
                        <CheckIcon style={{textAlign: "center", alignContent: 'center', alignItems: 'center', color: "#00FF00"}} />
                    </div>
                </td>
            )
        }else {
            return (
                <td>
                    {linearLoader && <Linear/>}
                    <div style={{textAlign: "center", alignContent: 'center', alignItems: 'center'}}>
                        <ClearIcon style={{textAlign: "center", alignContent: 'center', alignItems: 'center', color: "#FF0000"}} />
                    </div>
                </td>
            )
        }
}
