import React, {useEffect} from 'react';
import axios from "axios";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import Linear from "../CircularLoader/CircularLoader";

let permitido1 = 3;
let permitido2 = 5;

export default function FieldStatus(props) {
    let asignationId = props.props.asignationId;
    let fieldStatus = 4;
    const [statusAsign, setStatusAsign] = React.useState([]);
    const [linearLoader, setLinearLoader] = React.useState(true);
    const [limit, setLimit] = React.useState(1);


    useEffect( () => {
        if (limit === 1){
        axios.post(process.env.REACT_APP_API_URL + '/various/inquiries/get-dashboards-status-asign', {asignation_id: parseInt(asignationId)})
            .then((res) => {
                setStatusAsign(res.data.response);
            })
            .catch(() => {
                setStatusAsign([]);
            })
            .finally(() => {
                setLinearLoader(false);
                setLimit(0);
            });
        }else {
            console.log("Limite "+limit);
        }
        }, [asignationId]);



    let val1 = statusAsign.filter(id => id.statusId === permitido1);
    let val2 = statusAsign.filter(id => id.statusId === permitido2);
    if (val1.length > 0 || val2.length > 0){
        fieldStatus = (1);
    }else{
        fieldStatus = (0);
    }
    if (fieldStatus === 1){
        return(
            <td>
                {linearLoader && <Linear/>}
                <div style={{textAlign: "center", alignContent: 'center', alignItems: 'center'}}>
                    <CheckIcon style={{textAlign: "center", alignContent: 'center', alignItems: 'center', color: "#00FF00"}} />
                </div>
            </td>
        )
    }else {
        return (
            <td>
                {linearLoader && <Linear/>}
                <div style={{textAlign: "center", alignContent: 'center', alignItems: 'center'}}>
                    <ClearIcon style={{textAlign: "center", alignContent: 'center', alignItems: 'center', color: "#FF0000"}} />
                </div>
            </td>
        )
    }
}
