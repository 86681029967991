import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Tabs from "./components/TabsDash/Tabs"

function App() {
    return(
        <div>
            <BrowserRouter>
                <Switch>
                    <Route path={'/tabs/:userId'} exact={true} component={Tabs}/>
                    {/*<Route path={'/projects/:userId'} exact={true} component={Projects}/>*/}
                    {/*<Route path={'/auditProjects/:userId'} exact={true} component={AuditProjects}/>*/}
                    {/*<Route path={'/detail'} component={Detail}/>*/}
                    {/*<Route path={'/audit'} component={Audit}/>*/}
                    {/*<Route path={'/task'} component={Task}/>*/}
                </Switch>
            </BrowserRouter>
        </div>
    )
}

export default App;