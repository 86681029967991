import React, {useEffect} from 'react';
import axios from "axios";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import Linear from "../CircularLoader/CircularLoader";

export default function VideoFactory(props) {
    let SFV = props.props.SFV;
    let detailTaskId = props.props.detailTaskId;
    let videoFactory = 4;
    const [dataStaAud, setDataStaAud] = React.useState([]);
    const [linearLoader, setLinearLoader] = React.useState(true);
    const [limit, setLimit] = React.useState(1);


    useEffect( () => {
        if (limit === 1){
        axios.post(process.env.REACT_APP_API_URL + '/various/inquiries/get-dashboards-status-audit', {detail_task_id: parseInt(detailTaskId)})
            .then((res) => {
                setDataStaAud(res.data.response);
            })
            .catch(() => {
                setDataStaAud([]);
            })
            .finally(() => {
                setLinearLoader(false);
                setLimit(0);
            });
        }else {
            console.log("Limite "+limit)
        }
        }, [detailTaskId]);


    const setVidFactory = () => {
        let projects = dataStaAud.filter(factory => factory.fv === SFV);
        if (projects.length > 0){
            videoFactory = (1);
        }else{
            videoFactory = (0);
        }
    }


    setVidFactory();
    if (videoFactory ===1 ){
        return(
            <td>
                {linearLoader && <Linear/>}
                <div style={{textAlign: "center", alignContent: 'center', alignItems: 'center'}}>
                    <CheckIcon style={{textAlign: "center", alignContent: 'center', alignItems: 'center', color: "#00FF00"}} />
                </div>
            </td>
        )
    }else {
        return (
            <td>
                {linearLoader && <Linear/>}
                <div style={{textAlign: "center", alignContent: 'center', alignItems: 'center'}}>
                    <ClearIcon style={{textAlign: "center", alignContent: 'center', alignItems: 'center', color: "#FF0000"}} />
                </div>
            </td>
        )
    }
}
