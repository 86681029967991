import React, {useEffect} from 'react';
import axios from "axios";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import Linear from "../CircularLoader/CircularLoader";


export default function VisibleDashboard(props) {
    let answeredSurveyId = props.props.answeredSurveyId;
    let dashId = props.props.dashId;
    let reachId = props.props.reachId;
    const [dataVisible, setDataVisible] = React.useState(4);
    const [linearLoader, setLinearLoader] = React.useState(true);
    const [limit, setLimit] = React.useState(1);


    useEffect( () => {
        if (limit === 1){
        axios.post(process.env.REACT_APP_API_URL + '/various/inquiries/get-dashboard-visible', {dashboard_id: parseInt(dashId), reach_id: parseInt(reachId), answered_survey_id: parseInt(answeredSurveyId)})
            .then((res) => {
                setDataVisible(res.data.response);
            })
            .catch(() => {
                setDataVisible(8);
            })
            .finally(() => {
                setLinearLoader(false);
                setLimit(0);
            });
    }else{
        console.log("Limite "+limit)
        }
    }, [dashId, reachId, answeredSurveyId])



    if (dataVisible === 1){
        return(
            <td>
                {linearLoader && <Linear/>}
                <div style={{textAlign: "center", alignContent: 'center', alignItems: 'center'}}>
                    <CheckIcon style={{textAlign: "center", alignContent: 'center', alignItems: 'center', color: "#00FF00"}} />
                </div>
            </td>
        )
    }else {
        return (
            <td>
                {linearLoader && <Linear/>}
                <div style={{textAlign: "center", alignContent: 'center', alignItems: 'center'}}>
                    <ClearIcon style={{textAlign: "center", alignContent: 'center', alignItems: 'center', color: "#FF0000"}}/>
                </div>
            </td>
        )
    }
}
