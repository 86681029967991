import React, {useEffect} from 'react';
import axios from "axios";
import Spinner from './Spinner';
import {IntlProvider, loadMessages, LocalizationProvider} from '@progress/kendo-react-intl';
import esMessages from '../es.json';
import FormControl from "@material-ui/core/FormControl";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import Button from "@progress/kendo-react-buttons/dist/es/Button";
import Linear from "./CircularLoader/CircularLoader";
import {
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    InputLabel, MenuItem, Select,
    Snackbar,
    TextField
} from "@material-ui/core";
import {Alert, Autocomplete} from "@material-ui/lab";
import DashboardQuestions from "./Cells/DashboardQuestions";
import VideoFactory from "./Cells/VideoFactory";
import VisibleDashboard from "./Cells/VisibleDashboard";
import AuditStatus from "./Cells/AuditStatus";
import FieldStatus from "./Cells/FieldStatus";
import makeStyles from "@material-ui/core/styles/makeStyles";

loadMessages(esMessages, 'es-ES');

let select1 = [];
let select2 = [];
let select4 = [];
let dashId = 0;
let reachId = 0;
let locationId = 0;
let resetData = 4;
let bit = 5;
let funcion = "";
let menu = "";
let asignation = "Id Asignacion";
let tarea = "Nombre Tarea";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));


function Inquiries(props) {

    let userId = props.props.userId;
    const [dDash, setDDash] = React.useState(false);
    const [loader, setLoader] = React.useState(true);
    const [circular, setCircular] = React.useState(false);
    const [dReach, setDReach] = React.useState(true);
    const [dLocation, setDLocation] = React.useState(true);
    const [blockButton, setBlockButton] = React.useState(true);
    const [blockButtonC, setBlockButtonC] = React.useState(true);
    const [blockButtonL, setBlockButtonL] = React.useState(true);
    const [resetDone, setResetDone] = React.useState(false);
    const [resetError, setResetError] = React.useState(false);
    const [resetButton, setResetButton] = React.useState(true);
    const [openReason, setOpenReason] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [skip, setSkip] = React.useState(0);
    const [take, setTake] = React.useState(5);
    const [descripcion, setDescripcion] = React.useState("");
    const [descriptionMessage, setDescriptionMessage] = React.useState(false);
    const [reasonMessage, setReasonMessage] = React.useState(false);
    const [reason, setReason] = React.useState(0);
    const [bita, setBita] = React.useState(5);
    const classes = useStyles();

    useEffect(() => {
        axios.post(process.env.REACT_APP_API_URL + '/various/inquiries/get-dashboards-id', {user_id: userId})
            .then((res) =>{
                select1 = res.data.response;
            })
            .catch(() =>{
                select1 = [];
            })
            .finally(() => {
                setLoader(false);
            })
    },[userId]);

    const setNewDashId = (e) =>{
        dashId = (e.target.innerText);
        setDDash(true);
        getReach(dashId);
        setBlockButtonL(false);

    }

    const getReach = (dashId) => {
        axios.post(process.env.REACT_APP_API_URL + '/various/inquiries/get-dashboards-reach', {dashboard_id: parseInt(dashId)})
            .then((res) => {
                select2 = res.data.response;
            })
            .catch(() => {
                select2 = [];
            })
            .finally(() => {
                setDReach(false);
            });
    }

    const setNewReachId = (e) =>{
        reachId = (e.target.innerText);
        setDReach(true);
        getLocations(reachId);

    }

    const getLocations = (reachId) => {
        axios.post(process.env.REACT_APP_API_URL + '/various/inquiries/get-dashboards-locations', {reach_id: parseInt(reachId)})
            .then((res) => {
                select4 = res.data.response;
            })
            .catch(() => {
                select4 = [];
            })
            .finally(() => {
                setDLocation(false);
            });
    }

    const setNewLocationId = (e) =>{
        locationId = (e.target.innerText);
        setBlockButton(false);
        setBlockButtonC(false);
    }

    const getDataProjects = () => {
        setCircular(true);
        axios.post(process.env.REACT_APP_API_URL + '/various/inquiries/get-dashboards-data-projects', {reach_id: parseInt(reachId), location_id: parseInt(locationId)})
            .then((res) => {
                setData(res.data.response);
            })
            .catch(() => {
                setData([]);
            })
            .finally(() => {
                setDLocation(true);
                setBlockButtonC(true);
                setBlockButtonL(true);
                setCircular(false);
                setResetButton(false);
            });
    }

    const pageChange = (event) => {
        setSkip(event.page.skip)
        setTake(event.page.take)
    }

    const getVisibleDash = (props) => {
        let aux = props.dataItem;
        let answeredSurveyId = parseInt(aux.answeredSurveyId);
        return(
            <td>
                <div style={{textAlign: 'center', alignContent: 'center'}}>
                    <VisibleDashboard props={{
                        answeredSurveyId: answeredSurveyId,
                        reachId: reachId,
                        dashId: dashId
                    }}
                    />
                </div>
            </td>
        );
    }

    const fieldStatus = (props) =>{
        let compare = props.dataItem;
        let asignationId = compare.asignationId;
        return (
            <td>
                <div style={{textAlign: 'center', alignContent: 'center'}}>
                    <FieldStatus style={{textAlign: 'center', alignContent: 'center'}} props={{
                        asignationId: asignationId
                    }}
                    />
                </div>
            </td>
        );
    }

    const auditStatus = (props) =>{
        let compare = props.dataItem;
        let detailTaskId = compare.detailTaskId;
        return(
            <td>
                <div style={{textAlign: 'center', alignContent: 'center'}}>
                    <AuditStatus style={{textAlign: 'center', alignContent: 'center'}} props={{
                        detailTaskId: detailTaskId
                    }}
                    />
                </div>
            </td>
        );
    }

    const videoFactory = (props) =>{
        let compare = props.dataItem;
        let SFV = compare.statusFv;
        return(
            <td>
                <div style={{textAlign: 'center', alignContent: 'center'}}>
                    <VideoFactory style={{textAlign: 'center', alignContent: 'center'}} props={{
                      SFV: SFV,
                      detailTaskId: props.dataItem.detailTaskId
                    }}
                    />
                </div>
            </td>
        );
    }

    const dashQuestions = (props) =>{
        let aux = props.dataItem;
        let answeredSurveyId = parseInt(aux.answeredSurveyId)
        return(
            <td>
                <div style={{textAlign: 'center', alignContent: 'center'}}>
                    <DashboardQuestions style={{textAlign: 'center', alignContent: 'center'}} props={{
                        answeredSurveyId:  answeredSurveyId,
                        reachId: reachId,
                        dashId: dashId
                    }}
                    />
                </div>
            </td>
        );
    }



    const newButton = () => {
        axios.post(process.env.REACT_APP_API_URL + '/various/inquiries/reset-dashboard-location', {dashboard_id: parseInt(dashId), reach_id: parseInt(reachId), location_id: parseInt(locationId)})
            .then((res) => {
                resetData = (res.data.response);
                console.log(resetData);
            })
            .catch(() => {
                resetData = (8);
            })
            .finally(() => {
                if (resetData === 1){
                    setResetDone(true);
                    setResetButton(true);
                    funcion = "Reset-Location";
                    menu = "Consultas soporte (Dashboard)"
                    setBit(funcion, menu);
                }else {
                    setResetError(true);
                }
            });
    }

    const newSearch = () => {
        window.location.reload();
    }

    const cleanFilters = () => {
        if (dashId !== 0 && reachId === 0){
            setDDash(false);
            setDReach(true);
            setDLocation(true);
        }else if (reachId !== 0 && locationId === 0){
            setDLocation(true);
            setDReach(false);
            setDLocation(true);
        }
    }

    const closeDoneReset = () =>{
        setResetDone(false);
    }

    const closeErrorReset = () =>{
        setResetError(false);
    }

    const dialogReasons = () => {
        setOpenReason(true);
    }

    const changeStatus = (e) => {
        setReason(e.target.value);
    }

    const handleCloseReason = () => {
        setReason(0);
        setDescripcion("");
        setOpenReason(false);
    }

    const readReason = () => {
        if (reason !== 0){
            handleOpenSelectData();
        }else {
            setReasonMessage(true);
        }
    }

    const readLength = () => {
        if (descripcion.length >= 30){
            readReason();
        }else{
            setDescriptionMessage(true);
        }
    }

    const handleOpenSelectData = () => {
        setOpenReason(false);
        newButton();
    }

    const searchEvent = (e) => {
        setDescripcion(e.target.value);
    };

    const setBit = (funcion, menu) => {
        console.log(menu);
        console.log(funcion);
        axios.post(process.env.REACT_APP_API_URL + '/various/inquiries/insert-bit', {menu: ""+menu, function: ""+funcion, backup: null, current: null,
            reason: reason, observations: ""+descripcion, user: ""+userId, user_modified: ""+userId, asignation_id: null, location_id: parseInt(locationId), project_id: parseInt(dashId),
            task_id: parseInt(reachId), answered_survey_id: null, audit_id: null, task_detail_id: null, user_id: parseInt(userId), repo_multi_id: null, task_location_id: null})
            .then((res) => {
                setBita(res.data.response);
                console.log(res);
                console.log(bita);
                bit = res.data.response;
                console.log(bit);
            })
            .catch(() => {
                setBita(8);
            })
            .finally(() => {
                if (bit === 1){
                    console.log("Bitacora guardada");
                    console.log(bita);
                }else{
                    console.log("Bitacora sin guardar");
                    console.log(bita);
                }
            });
    }

    const closeDescriptionMessage = () => {
        setDescriptionMessage(false);
    }

    const closeReasonMessage = () =>{
        setReasonMessage(false);
    }

    return(
        <td>

            <Dialog
                open={resetDone}
                onClose={closeDoneReset}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"ÉXITO"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Reseteo de ubicacion en proceso, verifique en 20 min
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDoneReset} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={resetError}
                onClose={closeErrorReset}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"ERROR"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Ocurrió un error al resetear ubicacion
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeErrorReset} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={descriptionMessage}
                onClose={closeDescriptionMessage}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"ERROR"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        El comentario debe tener al menos 30 caracteres
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDescriptionMessage} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={reasonMessage}
                onClose={closeReasonMessage}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"ERROR"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Se debe seleccionar un motivo
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeReasonMessage} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openReason}
                onClose={handleCloseReason}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"INFORMACIÓN"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Antes de continuar, por favor proporcionar los siguientes datos
                    </DialogContentText>
                    <form style={{ textAlign: "center" }}>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="demo-dialog-native">Motivo</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={reason}
                                onChange={changeStatus}
                            >
                                <MenuItem value={1}>Problemas con aplicacion</MenuItem>
                                <MenuItem value={2}>Problemas con sincronizacion</MenuItem>
                                <MenuItem value={3}>Problemas con el pax</MenuItem>
                                <MenuItem value={4}>Error de sistema</MenuItem>
                                <MenuItem value={5}>Error de usuario</MenuItem>
                                <MenuItem value={6}>Solicitud de negocios</MenuItem>
                                <MenuItem value={7}>Solicitud de cliente</MenuItem>
                                <MenuItem value={8}>(Calidad) Confirmación extemporánea</MenuItem>
                            </Select>
                        </FormControl>
                    </form>
                    <form
                        style={{
                            textAlign: "center",
                            alignItems: "center",
                            alignContent: "center"
                        }}
                    >
                        <FormControl
                            style={{
                                textAlign: "center",
                                alignItems: "center",
                                alignContent: "center"
                            }}
                        >
                            <textarea
                                style={{ width: "100%" , heigth: "100%"}}
                                className={"full-space"}
                                placeholder={"Descripcion"}
                                onChange={searchEvent}
                                value={descripcion}
                                minLength="30"
                                maxLength="2000"
                                cols="100"
                                rows="10"
                            />
                        </FormControl>
                    </form>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseReason} color="primary">
                        NO
                    </Button>
                    <Button onClick={readLength} color="primary" autoFocus>
                        SI
                    </Button>
                </DialogActions>
            </Dialog>
            <div>
                <section>
            {loader && <Spinner/>}
            <FormControl style={{width: "33%"}}>
                <Autocomplete
                    id="size-small-standard"
                    options={select1}
                    size={"small"}
                    onChange={setNewDashId}
                    disabled={dDash}
                    getOptionLabel={(option) => option.dashboardName}
                                        renderInput={(params) => <TextField value={params.dashboardId} {...params} label={"Dashboard"} variant="standard" />}
                />
            </FormControl>
            <FormControl style={{width: "33%"}}>
                <Autocomplete
                    id="size-small-standard"
                    size={"small"}
                    options={select2}
                    onChange={setNewReachId}
                    disabled={dReach}
                    getOptionLabel={(option) => option.reachName}
                                        renderInput={(params) => <TextField {...params} label={"Alcance"} variant="standard"/>}
                />
            </FormControl>
            <FormControl style={{width: "33%"}}>
                <Autocomplete
                    id="size-small-standard"
                    options={select4}
                    size={"small"}
                    onChange={setNewLocationId}
                    disabled={dLocation}
                    getOptionLabel={(option) => option.locationName}
                    renderInput={(params) => <TextField {...params} label={"Ubicacion"} variant="standard"/>}
                />
            </FormControl>
                </section>
            </div>
            <br/>
            <br/>
            <div style={{textAlign: "center"}}>
                <button style={{fontSize:'14px'}} disabled={blockButtonC} onClick={getDataProjects}>Consultar</button> <rb/>
                <button style={{fontSize:'14px'}} disabled={blockButton} onClick={newSearch}>Nueva Consulta</button> <rb/>
                <button style={{fontSize:'14px'}} disabled={blockButtonL} onClick={cleanFilters}>Limpiar filtros</button>
            </div>
            <br/>
            <br/>
            <div>
                {circular && <Linear/>}
                <br/>
                <LocalizationProvider language="es-ES">
                    <IntlProvider locale="es" >
                        <div style={{textAlign: 'center'}}>
                            <Grid
                                style={{
                                    height: '480px',
                                    width: '100%',
                                    fontSize: '12px',
                                    fontFamily: 'Arial',

                                }}
                                data={(data).slice(skip,take + skip)}
                                skip={skip}
                                take={take}
                                total={data.length}
                                pageable={{buttonCount: 4, pageSizes: true}}
                                onPageChange={pageChange}
                                resizable
                            >
                                <GridColumn field={'asignationId'} title={"Id Asignacion"} width={"auto"}/>
                                <GridColumn field={'taskName'} title={tarea} width={"auto"}/>
                                <GridColumn field={'answeredSurveyId'} title={"Encuesta Contestada"} width={"auto"}/>
                                <GridColumn cell={getVisibleDash} title={"Visible Dashboard"} width={"auto"}/>
                                <GridColumn cell={fieldStatus} title={"Estatus campo"} width={"auto"}/>
                                <GridColumn cell={auditStatus} title={"Estatus Auditoria"} width={"auto"}/>
                                <GridColumn cell={videoFactory} title={"Fabrica Video"} width={"auto"}/>
                                <GridColumn cell={dashQuestions} title={"Preguntas Dashboard"} width={"auto"}/>
                            </Grid>
                            <br/>
                            <div placeholder={"RESET"} style={{textAlign: "right"}}>
                                <Button disabled={resetButton} onClick={dialogReasons}>Resetear Ubicacion</Button>
                            </div>
                        </div>
                    </IntlProvider>
                </LocalizationProvider>
            </div>
        </td>
    )
}

export default Inquiries;