import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import DonutSmallIcon from '@material-ui/icons/DonutSmall';
import Inquiries from "../Inquiries";
import DescriptionIcon from '@material-ui/icons/Description';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-prevent-tabpanel-${index}`}
            aria-labelledby={`scrollable-prevent-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-prevent-tab-${index}`,
        'aria-controls': `scrollable-prevent-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function ScrollableTabsButtonPrevent(props) {
    let userId = atob(props.match.params.userId);
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <td>
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs
                    style={{backgroundColor: "#FFFFFF"}}
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="off"
                    aria-label="scrollable prevent tabs example"
                >
                    <Tab label={"Consultas"} icon={<DonutSmallIcon />} aria-label="Consultas" {...a11yProps(0)} style={{color: "#FF995B"}}/>
                    {/*<Tab label={"Logs"} icon={<DescriptionIcon />} aria-label="Logs" {...a11yProps(1)} style={{color: "#FF995B"}}/>*/}
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <h3 style={{textAlign: 'center', color: '#FF995B'}}>Consultas</h3>
                <Inquiries props={{userId: userId}}/>
            </TabPanel>
            {/*<TabPanel value={value} index={1}>*/}
            {/*    <h3 style={{textAlign: 'center', color: '#FF995B'}}>Logs</h3>*/}
            {/*    <Inquiries props={{userId: userId}}/>*/}
            {/*</TabPanel>*/}
        </div>
        </td>
    );
}
